/* Container styles */
.visualization-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem;
  margin: 1rem 0;  /* Reduced from 2rem */
  height: 120px;
  position: relative;
}
.dots-container {
  position: relative;
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
}

.estimate-dot {
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease;
  top: 50%;
}

.estimate-tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  padding: 2px 8px;
  white-space: nowrap;
  font-size: 12px;
  font-family: Arial;
  z-index: 10;
}

.line-container {
  position: relative;
  height: 40px;
  width: 100%;
}

.number-line {
  position: relative;
  height: 2px;
  background-color: black;
  margin: 20px 10px;
}

.arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
}

.left-arrow {
  left: -5px;
  top: -4px;
  transform: rotate(135deg);
}

.right-arrow {
  right: -5px;
  top: -4px;
  transform: rotate(-45deg);
}

.tick {
  position: absolute;
  width: 2px;
  height: 12px;
  background-color: black;
  transform: translateX(-50%);
  top: -5px;
}

.min-tick {
  left: 0;
}

.mid-tick {
  left: 50%;
}

.max-tick {
  left: 100%;
}
.numberline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.75rem auto;  /* Reduced from 1.5rem */
  padding: 1rem;  /* Reduced from 1.5rem */
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: calc(100% - 2rem);  /* Adjusted for smaller margins */
  max-width: 1200px;
  box-sizing: border-box;
}

.svg-container {
    position: relative;
    width: 100%;
    height: 100px;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
}

.svg-container svg {
    width: 100%;
}

/* Main content alignment */
main > b, 
main > h2,
main > p.estimates-received {
    text-align: center;
    width: 100%;
}

/* Description card styling */
.description {
  background: white;
  padding: 1rem;  /* Reduced from 1.5rem */
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 0.75rem auto;  /* Reduced from 1.5rem */
  transition: all 0.2s ease;
  width: calc(100% - 1rem);  /* Adjusted for smaller margins */
  max-width: 1200px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
}
/* Add reasonable side margins on very wide screens */
@media (min-width: 1400px) {
    .description,
    .numberline {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

.description:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
}
.description h2 {
  margin: 0 0 0.5rem 0;  /* Reduced from 1rem */
  font-size: 1.25rem;
  color: #4b5563;
  text-align: left;
}

.description p {
    margin: 0 0 1rem 0;
    line-height: 1.5;
    text-align: left;
}

/* Source links styling */
.source-links-wrapper {
    width: 100%;
    box-sizing: border-box;
}

.source-links-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0.5rem 0;
    width: 100%;
    box-sizing: border-box;
}

.source-links-wrapper ul li {
    margin: 0.25rem 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    overflow-wrap: break-word;
}

.source-links-wrapper ul li a {
    color: #2563eb;
    text-decoration: none;
    overflow-wrap: break-word;
    word-break: break-all;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
}

.modal-content .source-links-wrapper,
.description .source-links-wrapper {
    padding-right: 1rem;

}

.source-links-wrapper ul li a:hover {
    text-decoration: underline;
}

/* Model name styling */
.model-name {
    text-align: right;
    color: #808080;
    font-size: 0.9rem;
    font-style: italic;
}

/* Modal styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    max-height: 85vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.modal-content h2,
.modal-content p {
    text-align: left;
}

.delete-button {
    background: #ef4444;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 1.5rem;
}

.delete-button:hover {
    background: #dc2626;
}

/* Initial state landing page */
.initial-state {
    text-align: center;
    padding: 2rem;
}

.initial-state h1 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
}

.initial-state h2 {
    font-size: 1rem;
    color: #666;
    font-weight: normal;
    margin: 1rem 0;
}

.initial-state img {
    margin: 1.5rem 0;
}

/* Loading state */
.loading-state {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
}