body {
    font-family: Arial, sans-serif;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.chart-container, .chart-header, .notes, .numberline {
    width: calc(100% - 3rem);
    margin: 1.5rem auto; /* Add vertical margin */
    box-sizing: border-box;
}
.chart-container {
    height: 24rem;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

nav, main {
    padding-top: 1.875rem;
    align-items: center;
}

main > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

main > * > * {
    display: block;
    justify-content: unset;
    align-items: unset;
}

header {
    background: white;
    color: #808080;
    top: 0;
    text-align: center;
    position: fixed;
    width: 100%;
    z-index: 1001;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-bottom: 1px solid lightgray;
}

.header-content {
    display: flex;
    height: 2.5rem;
    align-items: center;
    width: 100%;
}

.logo {
    font-size: 1.75rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}


h1, h2 {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.0em;
}

h2 {
    font-size: 1.25rem;
    color: #808080;
    margin-top: 0.0em;
    font-weight: normal;
}
h3 {
    font-size: 1.0rem;
    color: #808080;
    margin-top: 0.0em;
    font-weight: normal;
     margin: 0;
    padding: 0;
}

.notes {
    font-size: 0.875rem;
    color: #999;
    text-align: left;
}

.overlay {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0);
    z-index: 999;
}

.overlay.active {
    visibility: visible;
    opacity: 0.5;
}

/* Header and sidebar content */
#menu-toggle, .spacer {
    background: none;
    border: none;
    color: darkgray;
    cursor: pointer;
    align: left;
    font-size: 1.5rem;
    left: 0px;
    padding: 0.5rem;
}

.spacer {
    opacity: 0;
}

#menu-toggle:focus {
    outline: none;
}

/* Sidebar styles */
nav {
    position: fixed;
    width: 100%;
    max-width: 280px;
    background: #f9f9f9;
    left: -280px;  /* Just slightly more than the maximum width */
    transition: left 0.3s;
    top: 2.5rem;
    bottom: 0;
    overflow-y: auto;
    z-index: 1000;
}
nav.active {
    left: 0;  /* Make sure this isn't being overridden */
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

nav ul li {
    width: 100%;
}

.list-item {
    padding: 0.875rem;
    margin: 0.5rem 1.5rem;
    font-size: 1rem;  /* Increased from 0.8rem */
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    word-break: break-word;
    width: calc(100% - 3rem);
    box-sizing: border-box;
    cursor: pointer;
    transition: background 0.1s;
}

.list-item > div {
    margin-bottom: 0.5rem;
    line-height: 1.4;  /* Added for better readability */
}

.list-item p {
    margin: 0;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1rem;  /* Made the value slightly larger */
    font-weight: 500;   /* Added for emphasis */
}

.list-item:hover {
    background: lightgray;
}
/* Input area styles */
.input-area {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    z-index: 1000;
}

.input-area form {
    width: 100%;
    max-width: 1200px;
    padding: 0 1.5rem; /* More padding on sides */
    box-sizing: border-box;
}


.input-container {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 25px;
    overflow: hidden;
    width: 100%;
    padding: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.input-textarea {
    flex-grow: 1;
    border: none;
    padding: 12px 20px;
    background-color: transparent;
    color: #1a1a1a;
    outline: none;
    resize: none;
    overflow: hidden;
    font-family: Arial, sans-serif;
    font-size: 1rem;
    min-height: 24px;
}

.submit-button {
    min-width: 44px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #2563eb;
    color: white;
    border: none;
    transition: all 0.2s ease;
    display: flex;
    margin: 6px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    font-size: 1rem;
    font-weight: 500;
}

.submit-button:hover {
    background-color: #1d4ed8;
}

.submit-button:disabled {
    background-color: #94a3b8;
    cursor: not-allowed;
}

/* Media queries */
@media (min-width: 768px) {
    nav {
        width: 280px;
        left: 0;
    }

    main, .input-area {
        margin-left: 280px;
        width: calc(100% - 280px);
    }

    #menu-toggle {
        display: none;
    }
}